import React from "react";
import Link from "next/link";
import HeaderLogo from "@components/HeaderLogo/HeaderLogo";
import { Title } from "@components/ui";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import { SearchBar } from "@components/SearchBar/SearchBar";
import classNames from "classnames";
import styles from "./Header.module.scss";
import { ContentContainer } from "@components/ContentContainer/ContentContainer";
import { Button } from "@components/Button/Button";
import AdminLockIcon from "@components/Icons/AdminLockIcon";
import { useRouter } from "next/router";
import { THEME_COLOR } from "@utils/Constants";

import { Squash as Hamburger } from "hamburger-react";
import { motion, AnimatePresence } from "framer-motion";
export const Header = ({ showsSearchBar }) => {
    const t = GetTranslatedStrings();
    let headerColor = "rgba(255, 255, 255, 0.8)";
    const [showHamburgerMenu, setShowHamburgerMenu] = React.useState(false);
    const isFrontPage = useRouter().pathname === "/";
    return (
        <motion.div
            className={styles.container}
            initial={{
                opacity: isFrontPage ? 0 : 1,
                transform: isFrontPage
                    ? "translate(calc(-50% - 20px), 0px)"
                    : "translate(calc(-50% - 20px), 20px)",
            }}
            animate={{
                opacity: 1,
                transform: "translate(calc(-50% - 20px), 20px)",
            }}
            transition={{ duration: 1 }}
        >
            <header
                className={styles.header}
                style={{ background: headerColor }}
            >
                <ContentContainer className={styles.contentContainer}>
                    <HeaderLogo small={showsSearchBar} />

                    {showsSearchBar ? (
                        <SearchBar
                            small
                            style={{ marginLeft: 15, flexShrink: 1 }}
                        />
                    ) : (
                        <div style={{ flex: 1 }}></div>
                    )}

                    <nav style={{ height: 65, flexShrink: 0 }}>
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <div
                                className={classNames(
                                    styles.tryFreeButton,
                                    {
                                        [styles.showsSearchBar]: showsSearchBar,
                                    },
                                    styles.headerAccessories,
                                )}
                            >
                                <ul
                                    style={{
                                        display: "flex",
                                        flexGrow: 1,
                                        height: "100%",
                                    }}
                                >
                                    <HeaderMenuItem
                                        fontColor="#515151"
                                        text={t.solutions}
                                        dropdownItems={[
                                            {
                                                text: "Fyrirtæki",
                                                link: "/lausnir/fyrirtaeki",
                                            },
                                            {
                                                text: "Opinberir aðilar",
                                                link: "/lausnir/opinberir-adilar",
                                            },
                                        ]}
                                    />
                                    <HeaderMenuItem
                                        fontColor="#515151"
                                        text={t.customers}
                                        link="/vidskiptavinir"
                                    />
                                    <HeaderMenuItem
                                        fontColor="#515151"
                                        text={t.about_us}
                                        link="/um-okkur"
                                    />
                                </ul>

                                <Button
                                    size={"small"}
                                    href="https://meetings-eu1.hubspot.com/sveinborg-katla?uuid=dcf7faca-45c7-4528-855f-cc024a3b1be7"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        fontWeight: 600,
                                        alignSelf: "center",
                                        whiteSpace: "nowrap",
                                        padding: "7px 20px",
                                    }}
                                >
                                    {t.try_free}
                                </Button>
                            </div>
                            <a
                                className={styles.headerAccessories}
                                href="https://admin.laufid.is"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <AdminLockIcon
                                    height={25}
                                    width={25}
                                    style={{ marginLeft: 20 }}
                                />
                            </a>
                            <div className={styles.hamburger}>
                                <Hamburger
                                    toggled={showHamburgerMenu}
                                    color={THEME_COLOR}
                                    rounded
                                    duration={0.5}
                                    onToggle={(value) => {
                                        setShowHamburgerMenu(value);
                                    }}
                                />
                            </div>
                        </div>
                    </nav>
                </ContentContainer>
            </header>
            <AnimatePresence>
                {showHamburgerMenu && (
                    <motion.div
                        exit={{ opacity: 0, transform: "translateY(-10px)" }}
                        initial={{ opacity: 0, transform: "translateY(-10px)" }}
                        animate={{ opacity: 1, transform: "translateY(0px)" }}
                        transition={{ duration: 0.5 }}
                        className={styles.hamburgerMenu}
                        style={{ background: headerColor }}
                    >
                        <ul>
                            <HeaderMenuItem
                                text={t.solutions + " - Fyrirtæki"}
                                link="/lausnir/fyrirtaeki"
                            />
                            <HeaderMenuItem
                                text={t.solutions + " - Opinberir aðilar"}
                                link="/lausnir/opinberir-adilar"
                            />
                            <HeaderMenuItem
                                text={t.customers}
                                link="/vidskiptavinir"
                            />
                            <HeaderMenuItem
                                text={t.about_us}
                                link="/um-okkur"
                            />

                            <Button
                                size={"small"}
                                href="https://meetings-eu1.hubspot.com/sveinborg-katla?uuid=dcf7faca-45c7-4528-855f-cc024a3b1be7"
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    fontWeight: 600,
                                    alignSelf: "center",
                                    whiteSpace: "nowrap",
                                    padding: "7px 20px",
                                    marginBottom: 15,
                                }}
                            >
                                {t.try_free}
                            </Button>

                            <a
                                href="https://admin.laufid.is"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <HeaderMenuItem
                                    text={t.login}
                                    link="https://admin.laufid.is"
                                    icon={
                                        <AdminLockIcon
                                            height={25}
                                            width={25}
                                            style={{ marginRight: 10 }}
                                        />
                                    }
                                />
                            </a>
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
};

function HeaderMenuItem({
    icon,
    text,
    inverted,
    fontColor,
    className,
    link,
    dropdownItems,
}) {
    const router = useRouter();
    const currentRoute = router.pathname;
    let fontWeight = 500;
    if (currentRoute === link) {
        fontColor = THEME_COLOR;
        fontWeight = 600;
    }
    return (
        <li
            className={classNames(
                styles.menuItemText,
                {
                    [styles.inverted]: inverted,
                },
                className,
            )}
        >
            <Link
                href={link ? link : "#"}
                style={{
                    cursor: link ? "pointer" : "default",
                    height: "100%",
                }}
            >
                <Title
                    size={5}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: fontColor,
                        fontWeight,
                        height: "100%",
                    }}
                >
                    {icon && icon}
                    {text}
                </Title>
            </Link>
            <AnimatePresence>
                {dropdownItems && (
                    <motion.div
                        className={styles.dropdownContainer}
                        exit={{ opacity: 0, transform: "translateY(-10px)" }}
                        initial={{ opacity: 0, transform: "translateY(-10px)" }}
                        animate={{ opacity: 1, transform: "translateY(0px)" }}
                        transition={{ duration: 0.5 }}
                    >
                        <ul className={styles.dropdown}>
                            {dropdownItems.map((item) => {
                                let fontWeight = 500;
                                fontColor = "#515151";
                                if (currentRoute === item.link) {
                                    fontColor = THEME_COLOR;
                                    fontWeight = 600;
                                }
                                return (
                                    <li
                                        className={styles.dropdownItem}
                                        key={item.text}
                                    >
                                        <Link href={item.link}>
                                            <Title
                                                size={5}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    color: fontColor,
                                                    fontWeight,
                                                }}
                                            >
                                                {item.text}
                                            </Title>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </li>
    );
}
